import React, { useState} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';


import Button from '../../common/Button/Button';
import Input from '../../common/Input';
import Label from "../../common/Label/Label";
import { ReactComponent as Eye }  from '../../../static/images/eye.svg'

import style from './AuthForm.module.css'



export default function AuthForm () {

    const { checkAuth } = useActions();
    const navigate = useNavigate();
    const [login, setLogin] = useState(process.env.REACT_APP_LOGIN_DEV || '');
    const [password, setPassword] = useState(process.env.REACT_APP_PASSWORD_DEV || '');
    const [passwordType, setPasswordType] = useState('password');
    const { status, message } = useSelector((state) => state.auth);
    const [ emptyAuthStringError, setEmptyAuthStringError ] = useState(false);


    const onChangeAccount = (InputValue) => {
        setLogin(InputValue)
    }
    const onChangePassword = (InputValue) => {
        setPassword(InputValue)
    }


    const handleClickButtonSubmit = (event) => {
        event.preventDefault()

        const bool = isValid();

        if (bool){
            checkAuth({
                login,
                password
            }).then(() => {
                navigate('/lk')
            })
        }

        if (!bool) {
            setEmptyAuthStringError(true)
        } else {
            setEmptyAuthStringError(false)
        }

    }



    const handleClickButtonPassword = () => {
        setPasswordType(passwordType === 'password' ? 'text' : 'password');
    }


    const isValid = () => {
        return !!login.length && !!password.length;
    }

    return (
        <>
            <form className={style.formInput}>
                <div className={style.formItem}>
                    <Label title='Лицевой счет' >
                        <Input
                            onChange={onChangeAccount}
                            field='selectLogin'
                        />
                    </Label>
                </div>
                <div className={style.icon}>
                    <Eye onClick={handleClickButtonPassword}  />
                </div>
                <div className={style.formItem}>
                    <Label title='Пароль'>
                        <Input
                            type={passwordType}
                            onChange={onChangePassword}
                            field='selectPassword'
                        />
                    </Label>

                </div>
                {status? <div className={style.message}>{message}</div> : ''}
                { emptyAuthStringError ? <div className={style.message}>Введите логин и пароль</div> : '' }
                <div className={style.politika}>
                    <>{process.env.REACT_APP_LOGIN_PAGE_TEXT_COOKIE || 'Мы собираем файлы cookie в соответствии'} </>
                    <a href={process.env.REACT_APP_LOGIN_PAGE_URL || "/static_files/politika.pdf"}>
                        {process.env.REACT_APP_LOGIN_PAGE_URL_TEXT || "с политикой конфиденциальности."}</a>
                </div>
                <Button type="submit" size={'large'} onClick={handleClickButtonSubmit} authSize={true}>
                    Войти
                </Button>
            </form>
        </>
    )
}
